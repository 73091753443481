@import '~bootstrap/scss/bootstrap';
@import '~toastr/toastr';

:root {
  --altura-header: 116.4px;
  --altura-img-home: 635px;
  --altura-img-cuenta: 450px;
  --altura-img-ayuda: 450px;
  --altura-mas-buscados-home: 913px;
  --bs-pagination-active-border-color: black;
  --altura-img-editorial-info: 346px;

  --altura-header-mobile: 116.4px;
//   --altura-img-home-mobile: 326px;
  --altura-img-home-mobile: 475px;
}

$color-primary: #ffc605;


/*FUENTES*/
@font-face {
  font-family: 'Playfair Display';
  src: url('../fonts/Playfair_Display/static/PlayfairDisplay-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'PlayfairDisplay-SemiBold';
  src: url('../fonts/Playfair_Display/static/PlayfairDisplay-SemiBold.ttf') format('truetype');
}


@font-face {
  font-family: 'Prompt';
  src: url('../fonts/Prompt/Prompt-Regular.ttf') format('truetype');
  /* Agrega más estilos y formatos de fuente si los tienes */
}

@font-face {
  font-family: 'Prompt-Medium';
  src: url('../fonts/Prompt/Prompt-Medium.ttf') format('truetype');
  /* Agrega más estilos y formatos de fuente si los tienes */
}


@font-face {
  font-family: 'Prompt-SemiBold';
  src: url('../fonts/Prompt/Prompt-SemiBold.ttf') format('truetype');
  /* Agrega más estilos y formatos de fuente si los tienes */
}

@font-face {
  font-family: 'Prompt-Bold';
  src: url('../fonts/Prompt/Prompt-Bold.ttf') format('truetype');
  /* Agrega más estilos y formatos de fuente si los tienes */
}

@font-face {
  font-family: 'Outfit';
  src: url('../fonts/Outfit/static/Outfit-Light.ttf') format('truetype');
  /* Agrega más estilos y formatos de fuente si los tienes */
}

@font-face {
  font-family: 'Outfit-Regular';
  src: url('../fonts/Outfit/static/Outfit-Regular.ttf') format('truetype');
  /* Agrega más estilos y formatos de fuente si los tienes */
}

@font-face {
  font-family: 'Outfit-Bold';
  src: url('../fonts/Outfit/static/Outfit-Bold.ttf') format('truetype');
  /* Agrega más estilos y formatos de fuente si los tienes */
}

@font-face {
  font-family: 'Outfit-SemiBold';
  src: url('../fonts/Outfit/static/Outfit-SemiBold.ttf') format('truetype');
  /* Agrega más estilos y formatos de fuente si los tienes */
}

@font-face {
  font-family: 'Outfit-ExtraBold';
  src: url('../fonts/Outfit/static/Outfit-ExtraBold.ttf') format('truetype');
  /* Agrega más estilos y formatos de fuente si los tienes */
}

@font-face {
  font-family: 'Outfit-Medium';
  src: url('../fonts/Outfit/static/Outfit-Medium.ttf') format('truetype');
  /* Agrega más estilos y formatos de fuente si los tienes */
}

@font-face {
    font-family: 'Font Awesome'; /* Nombre de la fuente de iconos */
    font-style: normal;
    font-weight: 900; /* Peso de la fuente para los iconos */
    src: url('../fonts/Fontawesome/webfonts/fa-solid-900.woff2') format('woff2'); /* Ajusta la ruta a tu archivo woff2 */
}

.fs-15px{
	font-size: 15px !important;
}

.bg-anaya{
	background-color: #ffc605
}

.bg-grey {
	background-color: #333;
}

.bg-black {
	background-color: #000;
}

.multiselect__option--highlight {
  background: #ffca2c !important;
}

.error{
	color:indianred;
	font-family: Outfit-Regular;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
}

.cursor-pointer{
	cursor: pointer;
}

.t-0{
	top: 0;
}

.mr-auto{
	margin-right: auto;
}

.ml-auto{
	margin-left: auto;
}

.w-fit-content{
	width: fit-content;
}

.btn-white{
	--bs-btn-bg: #fff;
}

.ayuda h1{
  font-size: 26px;
}

.ayuda h2{
  font-size: 24px;
}

.ayuda h3{
  font-size: 22px;
}

.ayuda h4{
  font-size: 20px;
}

.ayuda .respuesta-texto p, .ayuda .respuesta-texto li{
  font-size: 15px;
}

.mm-disabled {
	pointer-events: none;
	opacity: 0.5;
  }

/*HEADER*/
header{

		transition: 0.5s ease-in-out;
		z-index:999;
		width: 100%;
		height: var(--altura-header);
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin: 0px !important;
		padding: 30px 70px;
		top: 0;
		position: sticky;
		color: #ffffff;
		.cga{
			width: 148.7px;
			padding: 0 19.2px 0.3px 0;
		}
		.menu__box{
			z-index:2;
		}
		.menu{
			display: flex;
			gap: 40px;
			padding: 0;

			&.action-menu{
				gap: 15px;

				@media (max-width: 1000px) {
					gap:0;
				}
			}

		}
		.enlace-menu{
			font-family: Prompt-Semibold; ;
			color: #ffffff;
			font-size: 12px;
			line-height: 4.17;
			letter-spacing: 2px;
			text-align: center;
			text-transform: uppercase;
			text-decoration: none;
			position: relative;
			display: inline-block;
			height: fit-content;
			transition: all 0.3s ease;
			&:hover {
				opacity: 0.7;
			}
			&.selected {
				&:hover {
					opacity: 1;
				}
			}
			&.active,
			&.carrito-selector {
				opacity: 1 !important;
			}
		}

		.enlace-menu.selected::after {
			content: "";
			position: absolute;
			bottom: 8px;
			left: 0;
			width: 100%;
			border-bottom: 5px solid #ffc602;
			transform: scale(0.8, 0.5); /* Ajusta los valores de escala según tu preferencia */
		}

		.shopping-cart{
			width: 27px;
			height: 29px;
			margin: 0 3px 0 0;
			object-fit: contain;
		}
		.ml-auto{
			margin-left: auto;
		}

		/*MOBILE*/
		#menu__toggle {
		  opacity: 0;
		}
		#menu__toggle:checked + .menu__btn > span {
		  transform: rotate(45deg);
		}
		#menu__toggle:checked + .menu__btn > span::before {
		  top: 0;
		  transform: rotate(0deg);
		}
		#menu__toggle:checked + .menu__btn > span::after {
		  top: 0;
		  transform: rotate(90deg);
		}
		#menu__toggle:checked ~ .menu__box {
		  left: 0 !important;
		}
		.menu__btn {
		  position: fixed;
		  top: Calc(var(--altura-header)/2);
		  left: 20px;
		  width: 26px;
		  height: 26px;
		  cursor: pointer;
		  z-index: 1;
		}
		.menu__btn > span,
		.menu__btn > span::before,
		.menu__btn > span::after {
		  display: block;
		  position: absolute;
		  width: 100%;
		  height: 2px;
		  background-color: #ffffff;
		  transition-duration: .25s;
		}

		.menu__btn > span::before {
		  content: '';
		  top: -8px;
		}
		.menu__btn > span::after {
		  content: '';
		  top: 8px;
		}
		.menu__box {
		  display: block;
		  position: fixed;
		  top: 0;
		  left: -100%;
		  width: 300px;
		  height: 100%;
		  margin-top: var(--altura-header);
		  padding: 0px 0;
		  list-style: none;
		  background-color: #ffffff;
		  box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
		  transition-duration: .25s;
		}
		.menu__item {
		  transition-duration: .25s;
		}
		.menu__item:hover {
		  background-color: #CFD8DC;
		}

		.hamburger-menu .language-selector .enlace-menu,.hamburger-menu a.enlace-menu{
			color: #000000;
		}

		/*IDIOMA*/

		hr{

		  margin: 0;
		  color: #dadada;
		  border: 1px;
		  border-top: var(--bs-border-width) solid;
		  opacity: 1;
		}

		.active .idioma-icon{
		  transform: rotate(0);
		}

		.idioma-icon{
		  transform: rotate(180deg);
		}

		.language-selector {
		  position: relative;
		  display: inline-block;
		}

		.selected-language {
		  background-color: transparent;
		  border: none;
		  cursor: pointer;
		  display: flex;
		  align-items: center;
		}

		.selected-language-text {
		  margin-right: 5px;
		}

		.language-list {
		  position: absolute;
		  top: 100%;
		  left: 0;
		  z-index: 1;
		  display: none;
		  padding: 0;
		  margin: 0;
		  border-radius: 10px;
		}

		.language-list ul {
		  list-style: none;
		  background-color: #fff;
		  padding: 10px 20px 5px;
		  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.15);
		  border-radius: 10px;
		}

		.language-list ul li {
		  margin-bottom: 10px;
		  margin-top: 10px;
		}

		.language-list ul li a {
		  display: block;
		  text-decoration: none;
		}

		.language-list ul li a span {
		  display: inline-block;
		  margin-right: 5px;
		}

		.language-selector.active .language-list {
		  display: block;
		}

		.language-selector-color{
			color:#828282 !important;
		}

		.language-selector-color.selected-list{
			color: #ffc602 !important;
		}

		.language-selector ul span{
			flex-grow: 0;
			font-family: Prompt-SemiBold;
			font-size: 11px;
			font-stretch: normal;
			font-style: normal;
			line-height: 0.91;
			letter-spacing: 2px;
		}

		.language-selector ul{
			line-height: 0.91;
		}

		/*Menú usuario*/
		.login-user{
			cursor: pointer;
		}

		.user-icon{
			width: 39px;
			height: 40px;
			background-image: url('/img/icons/user-white.svg');
			background-position: center center;
			background-repeat: no-repeat;
			transition: all 0.3s ease;
			&:hover {
				opacity: 0.7;
			}
		}

		.opaco .user-icon {
			background-image: url('/img/icons/user-black.svg');
			background-position: center center;
			background-repeat: no-repeat;
			transition: all 0.3s ease;
			&:hover {
				opacity: 0.7;
			}
		}

		.popover {
		    max-width: 200px;
		    padding: 10px;
		    background-color: #f0f0f0;
		    border: 1px solid #ddd;
		    border-radius: 4px;
		    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
		}

		.user-menu {
		  position: relative;
		  display: inline-block;
		}

		.popover-menu {
		  position: absolute;
		  z-index: 1;
		  display: none;
		  padding: 0;
		  margin: 0;
		  border-radius: 10px;
	      width: 425px;
	      height: 214px;
	      background-color: #fff; /* Fondo blanco */
	      padding: 41px;
	      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Sombra para dar profundidad */
	      color: #3f4651;
	      background-color: white;
		top: 85px;
		right: -68px;

			.nombre-usuario{
			  flex-grow: 0;
			  font-family: Outfit-Bold;
			  font-size: 20px;
			  line-height: 1.2;
			  letter-spacing: normal;
			  text-align: left;
	  		  color: #3f4651;
	  		  margin-bottom: 27px;
			}
			.button-mi-cuenta{
				margin-bottom: 18px;
				a {
					height: 44px;
					padding-top: 8px;
				}
			}

			.cerrar-sesion{
				color: #ffc605;
				flex-grow: 0;
				font-family: Outfit-SemiBold;
				font-size: 16px;
				line-height: 0.63;
				letter-spacing: normal;
				text-align: center;
				text-decoration: none;
				display: block;
				&:hover {
					text-decoration: underline !important;
				}
			}


		}



		.user-menu.active .popover-menu {
		  display: block;
		}

		.user-menu-color{
			color:#828282 !important;
		}

		.user-menu-color.selected-list{
			color: #ffc602 !important;
		}

		.user-menu ul span{
			flex-grow: 0;
			font-family: Prompt-SemiBold;
			font-size: 11px;
			font-stretch: normal;
			font-style: normal;
			line-height: 0.91;
			letter-spacing: 2px;
		}

		.user-menu ul{
			line-height: 0.91;
		}

}

header.opaco{
 	background-color: white !important;
 	color: #000000;
	box-shadow: 0 4px 24px 0 rgba(34,41,47,.1);
	.enlace-menu{
		color: #000000;
	}
	.white{
	  display: none;
	}
	.user-icon {
		background-image: url('/img/icons/user-black.svg');
		background-position: center center;
		background-repeat: no-repeat;
	}

}

.opaco .menu__btn > span,
.opaco .menu__btn > span::before,
.opaco .menu__btn > span::after {
  background-color: #828282;
}

header:not(.opaco) .grey {
  display: none
}


header .user-logado{
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #000;
	border-radius: 50%;
	color: #ffffff;
	cursor: pointer;
}

.punto-carrito{
	width: 10px;
	height: 10px;
	background-color: #ffc602;
	border-radius: 50%;
	position: absolute;
	margin-right: -20px;
	margin-bottom: 8px;
}

.carrito-list {
  position: absolute;
  width: 400px;
  top: 68px;
  right: 0;
  z-index: 1;
  display: none;
  padding: 0;
  margin: 0;
  background-color: #f1f1f1;
  .listado-productos {
	max-height: 40vh;
    overflow-y: scroll;
  }
}

.enlace-menu .carrito-list{
	font-size: initial;
	font-weight: initial;
	font-stretch: initial;
	font-style: initial;
	line-height: initial;
	letter-spacing: initial;
	text-align: initial;
	text-transform: initial;
}

.carrito-list ul {
  list-style: none;
  background-color: #fff;
  padding: 20px 30px 5px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.carrito-list ul li {
  margin-bottom: 10px;
  margin-top: 10px;
}

.carrito-list ul li a {
  display: block;
  text-decoration: none;
}

.carrito-list ul li a span {
  display: inline-block;
  margin-right: 5px;
}

.carrito-header.active .carrito-list {
  display: block;
  box-shadow: 0 6px 28px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.carrito-header .carrito-list .header-list {
  display: block;
  background-color: white;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.05);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

}

.carrito-header .header-list .mi-carrito{
	font-family: Outfit-Bold;
	font-size: 21px;
	color: #1d1f21;
	text-transform: none;
	padding-left: 10px;
	padding-right: 10px;
}

.carrito-header .header-list .unidades-header{
  font-family: Outfit-Medium;
  font-size: 16px;
  color: #afb5bf;
}

.carrito-header .header-list{
	font-weight: initial;
	font-stretch: initial;
	font-style: initial;
	line-height: initial;
	letter-spacing: initial;
	text-align: initial;
	text-transform: initial;
}


.carrito-header .producto{
	background-color: #fff;
	padding: 15px 5px;
	border-radius: 8px;
	box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.05);

	@media (max-width: 600px) {
		.titulo{
			font-size: 15px;
		}
	}
}

.carrito-header {
	.delete-product{
		border: 1px solid #e2e2e2;
		border-radius: 70px;
		display: block;
		padding: 4px;
		text-align: center;
		height: 35px;
		width: 35px;

		img {
			height: 18px;
			margin-left: 3px;
		}
	}
}


.producto .titulo{
	font-family: Outfit-SemiBold;
	font-size: 16px;
	color: #3f4651;
}
.producto .unidades{

	font-family: Outfit-Medium;
	font-size: 14px;
	color: #afb5bf;
}

.producto .precio{
	font-family: Outfit-SemiBold;
	font-size: 16px;
	color: #3f4651;
}

.carrito-list .subtotal{
	background-color: #fff ;
	box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.05);
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}

.carrito-list .subtotal .subtotal-text{
	font-family: Outfit-Medium;
	font-size: 15px;
	color: #afb5bf;
}

.carrito-list .subtotal .cantidad{
  font-family: Outfit-Bold;
  font-size: 17px;
  color: #1d1f21;
}

.carrito-list .fin-compra {
  font-family: Outfit-SemiBold;
  font-size: 15px;
  color: #fff;
  height: 46px;
  --bs-btn-active-color: #fff;
  --bs-btn-disabled-color: #fff;
}

.carrito-list .right-arrow{
	margin-left:auto;
}

.carrito-header .shopping-cart  {
	cursor:pointer;
	transition: all 0.3s ease;
	&:hover {
		opacity: 0.7;
	}
}

/*MODALS*/
/*LOGIN*/
.modal{
	background-color: rgba(0, 0, 0, 0.38);
	color: #3f4651 ;
}

.login-modal .modal-body{
  padding: 0;
}

.login-modal{
  padding: 41px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 425px;
}

.modal-login-input-header .opcional{
  font-family: Outfit-Regular;
  font-size: 14px;
  text-align: left;
  color: #3f4651;
}

.modal-login-header{
  font-family: Outfit-Bold;
  font-size: 20px;
  text-align: center;
  color: #3f4651;

  p {
	font-size: 17px;
	font-family: Outfit-Regular;
  }
}

.modal-login-section-title {
	text-align: left;
	font-size: 16px;
	font-family: Outfit-Bold;
	color: #3F4651;

	span {
		padding: 3px;
		border-radius: 50px;
		background-color: #FFC605;
		margin-right: 0.5rem;
		color: white;
		width: 30px;
		display: inline-flex;
		height: 30px;
		justify-content: center;
    	align-items: center;
	}
}

.modal-login-section-desc {
	font-family: Outfit-Regular;
	text-align: left;
	font-size: 12px;
	color: black;
}

.modal-login-section-ayuda {
	background-color: rgba(255, 198, 5, 0.16);
	padding: 0.5rem 1rem;
	border-radius: 10px;
}


.modal-login-input-header{
  font-family: Outfit-Semibold;
  font-size: 17px;
  text-align: left;
  color: #3F4651;
}

.modal-login-input-text{
	height: 46px;
	flex-grow: 0;
	font-family: Outfit-Regular;
	font-size: 14px;
	line-height: 1.71;
	letter-spacing: normal;
	text-align: left;
	color: #3F4651;
	width: 100%;
	padding: 0.375rem 0.75rem;
	appearance: none;
	border-radius: var(--bs-border-radius);
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	background-clip: padding-box;
	border: var(--bs-border-width) solid var(--bs-border-color);
}

.modal-login-olvido{
  flex-grow: 0;
  font-family: Outfit-Medium;
  font-size: 14px;
  line-height: 0.71;
  letter-spacing: normal;
  text-align: right;
  color: rgba(63, 70, 81, 0.4);
  text-align: center;
  cursor: pointer;
}

.modal-login-acceder-div{
  	text-align: right;
	display: flex;
	justify-content: right;

	#form-registro-usuario-submit{
		width: 130px;
		align-items: center;
		display: flex;
		justify-content: center;
	}
}

.button-login-text, .button-login-text:hover{
  font-family: Outfit;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  height: 43px;
  padding: 0 24px !important;
  width: fit-content;
}

.modal-login-registrate{
  font-family: Outfit-SemiBold;
  font-size: 14px;
  line-height: 0.71;
  letter-spacing: normal;
  text-align: center;
  color: rgba(63, 70, 81, 0.4);
}

.registro-modal{
  padding: 30px;
}
.check-registro{
	min-width: 20px;
	min-height: 20px;
}

#nombreColegio {
	padding-right: 60px;
}

#selectedColegioContainer .remove-school-button{
	height: 100%;
	display: flex;
	top: 0;
	align-items: center;
	right: 20px;

	.key-icon{
		width: 18px;
		height: 18px;
	}
}

.modal-registro-consentimientos{
	flex-grow: 0;
	font-family: Outfit-Regular;
	font-size: 14px;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: center;
	color: #3f4651;
}
.modal-login-registrate-click{
	cursor: pointer;
	color: #ffc605;
	transition: all 0.3s ease;
	&:hover {
		text-decoration: underline;
	}
}

.br-registro{
	border-right: 1px solid #e0e5ed;
	padding-right: 40px;
}

.br-registro-right {
	padding-left: 40px;
}

.custom-checkbox{
	width: fit-content;
	margin: auto;
}

.form-check-label{
	margin-left: 11.5px;
	cursor: pointer;
}

#modalColegios {
	.colegio-row {
		color: black;

		button{
			background: #F4F4F4;
			height: 100%;
			color: black;
			border: 0;

			&:hover{
				background: #D7D7D7;
			}

			span{
				font-size:10px;
			}
		}
	}
}

#school-search-button{
	width: 148px;
	display: flex;
    justify-content: center;
}

/*COMUNIDAD*/

.modal-comunidad-input-header{
  font-family: Outfit-Bold;
  font-size: 14px;
  text-align: left;
  color: #3f4651;

  &__subtitle{
	font-family: Outfit-Regular;
	font-size: 14px;
  }
}

.text-error-comunidad{
  font-family: Outfit;
  font-size: 14px;
  text-align: left;
  color: red;
}

/* Estilos para el checkbox personalizado */
.custom-checkbox input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.custom-checkbox input[type="checkbox"] + .checkmark {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #fbbd08; /* Color amarillo */
    display: inline-block;
    position: relative;
    text-align: center;
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark {
    background-color: #fbbd08; /* Color de fondo cuando está marcado */
}

/* Agregar el icono de tick de Font Awesome cuando el checkbox está marcado */
.custom-checkbox input[type="checkbox"]:checked + .checkmark::after {
    content: "\f00c"; /* Código del icono tick en Font Awesome */
    font-family: "Font Awesome"; /* Nombre de la fuente de iconos */
    font-weight: 900; /* Peso de la fuente para el tick */
    position: absolute;
    top: 1px;
    left: 3px;
    color: #fff; /* Color del tick */
    font-size: 12px; /* Tamaño del tick */
}

/* Estilos para el checkbox personalizado */
.custom-checkbox input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.custom-checkbox input[type="radio"] + .checkmark {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #fbbd08; /* Color amarillo */
    display: inline-block;
    position: relative;
    text-align: center;
}

.custom-checkbox input[type="radio"]:checked + .checkmark {
    background-color: #fbbd08; /* Color de fondo cuando está marcado */
}

/* Agregar el icono de tick de Font Awesome cuando el checkbox está marcado */
.custom-checkbox input[type="radio"]:checked + .checkmark::after {
    content: "\f00c"; /* Código del icono tick en Font Awesome */
    font-family: "Font Awesome"; /* Nombre de la fuente de iconos */
    font-weight: 900; /* Peso de la fuente para el tick */
    position: absolute;
    top: 1px;
    left: 3px;
    color: #fff; /* Color del tick */
    font-size: 12px; /* Tamaño del tick */
}

#submitComunidad{
	width: 88px;
	display: flex;
    justify-content: center;
    align-items: center;
}

.recuperar1-modal{
	padding-top: 60px;
	padding-left: 150px;
	padding-right:  150px;
	padding-bottom:  72px;
}

.header-recuperar{
  font-family: Prompt-SemiBold ;
  font-size: 28px;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.text-recuperar{
  font-family: Outfit-Medium;
  font-size: 17px;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #3f4651;
}

.modal-recuperar-input-header{
  font-family: Outfit-Regular;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #3f4651;
}

.key-icon{
    width: 23.4px;
    height: 23.3px;
}

.close-modal{
	position: absolute;
	top: 21px;
	right: 21px;
	cursor: pointer;
}

/*BODY*/
.cabecera-imagen-cuenta{
	height: var(--altura-img-cuenta);
	width: 100%;
	top: 0;
	background-size: cover;
	background-image:
        linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        url('/img/mi-cuenta-header.webp');
    padding: 71px;
	position: relative;
	margin-top: -120px;
}

.cabecera-imagen{
	height: var(--altura-img-home);
	width: 100%;
	background-size: cover;
	background-image:
        linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        url('/img/home-header.jpeg');
    padding: 71px;
	display: flex;
	flex-direction: column;
	justify-content: end;
	position: relative;
	top: -120px;
}

.cabecera-imagen-docente {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("/img/docente-header.jpeg");
}

.home-header-title{
	font-family: Prompt-Semibold;
	font-size: 14px;
	line-height: 1.57;
	letter-spacing: 2px;
	text-align: left;
	color: #fff;
	margin-bottom: 15px;
}

.cabecera-imagen-docente {

	.home-header-title{
		font-size:25px;
	}
}

.home-header-text{
  font-family: PlayfairDisplay-SemiBold;
  font-size: 50px;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  margin-top: 15px;
  margin-bottom: 70px;
}

.linea-home-header {
  width: 103px;
  height: 2px;
  background-color: #ffc602;
  border-bottom: 2px;
  display: block;
}
body{
	background-color: #f1f1f1;
}

.home-mas-buscados{
	font-family: Playfair Display;
	font-size: 36px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.03;
	letter-spacing: normal;
	text-align: center;
	color: #000;
}

hr.subrayado-buscados{
	width: 67px;
	height: 5px;
	margin: auto;
	background-color: #ffc602;
	border: 0;
	color: #ffc602;
	opacity: 1;
}

.buscador-static .buscador-general{
	position: static;
}

.lupa-icon{
	width: 25px;
	height: 25px;
	margin-right: 10px;
}

.buscador-general {
	position: absolute;
	top: calc(var(--altura-img-home) - 40px);
	z-index: 1;
	padding: 0;
	border-radius: 10px;
	width: 90%;
	min-height: 251px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	background-color: white;
	left: calc(10% / 2);
	padding: 60px 125px 60px 125px;
	z-index: 1;
	box-shadow: 0 6px 28px 0 rgba(0, 0, 0, 0.1);

	@media (max-width: 768px) {
		top: calc(var(--altura-img-home-mobile) - 40px);
	}
}

#buscador-general-lecturas {
	.div-busqueda{
		margin-top: 0.5rem !important;
		box-shadow: 0 6px 28px 0 rgba(0, 0, 0, 0.1);
		background: white;
		z-index: 9;
		position: relative;
		padding: 4rem;
		border-radius: 10px;
	}
}


.buscador-general .title {
    flex-grow: 0;
  font-family: Prompt ;
  font-size: 28px;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  padding-bottom: 10px;
  img {
	height: 35px;
    margin-right: 8px;
    position: relative;
    top: -2px;
  }

  	@media (max-width: 768px) {
		font-size: 22px;
	}

	@media (max-width: 480px) {
		font-size: 18px;
	}
}


input:disabled, select:disabled {
	background-color: var(--bs-secondary-bg);
}

.multiselect--disabled .multiselect__select {
	height: 100%;
	top: 0;
  }


.buscador-general .div-button{
  padding-left: 0;
}


.buscador-general .div-input{
  padding-right: 0;
  height: 59px;
  border: 0;
}


.buscador-general .input-ppal{
  background-color: rgba(216, 216, 216, 0.2);
  border: 0;
  height: 59px;
  font-family: Prompt ;
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #898e92;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0.375rem 1.75rem;
}


.buscador-general .buscar-ppal{
  border: 0;
  height: 59px;
  width: 100%;
  color: white;
  font-family: Prompt-Bold;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 1px;
  color: #fff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  text-transform: uppercase;
}

.buscador-general .busqueda-avanzada-text{
  flex-grow: 0;
  font-family: Prompt;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  margin-top: 10px;
}

.buscador-general .buscar-avanzada{
	font-family: Outfit-SemiBold;
	font-size: 15px;
	line-height: normal;
	letter-spacing: normal;
	color: #fff;
	padding: 14px;
}

.buscador-general .buscar-avanzada-headers{
	font-family: Outfit-Bold;
	font-size: 17px;
	line-height: 1.2;
	letter-spacing: normal;
	color: #3f4651;
}

.busqueda-a-icon{
  transform: rotate(180deg);
}

.height-fit-content .busqueda-a-icon{
  transform: rotate(0);
}

#busqueda-avanzada{
	display: none;
}

.height-fit-content #busqueda-avanzada{
	display: flex;
}

.height-fit-content{
	height: fit-content;
}

/*
.select-buscador{
  background-color: white;
  max-height: 300px;
  overflow: auto;
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem top 0.75rem;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
}

.select-buscador input{
  border:0;
}

.select-buscador li{
   list-style:none;
   cursor: pointer;
}

.div-parent-multiselect{
  position: relative;
  width: 100%;
}


.multiselect--disabled{
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
*/

/*Los Más Buscados*/

.resumen-compra .resumen-carrito .portada {
    border-radius: 5px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    width: 100%;
	height: auto;
}


.buscador-portada-img{
    border-radius: 5px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    width: 100%;
    transition: all 0.3s ease;
    &:hover {
      opacity: 0.6;
    }
}

.buscados-card-titulo{
  flex-grow: 0;
  padding: 0 10px 0 0;
  font-family: Prompt-SemiBold;
  font-size: 22px;
  line-height: 1.27;
  letter-spacing: normal;
  color: #1d1f21;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.7;
  }
}

.buscados-card-titulo a{
  color: initial;
  text-decoration: none;
}

.carrito-list .listado-productos .titulo a{
	color: initial;
	text-decoration: none;
}

.buscados-card-colec{
  align-self: stretch;
  flex-grow: 0;
  font-family: Outfit-Regular;
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  color: #afb5bf;
}

.buscados-card-editorial{
  font-family: Outfit-Regular;
  font-size: 14px;
  align-self: stretch;
  flex-grow: 0;
  line-height: normal;
  letter-spacing: normal;
  color: #afb5bf;
}

.buscados-card-precio{
  flex-grow: 0;
  font-family: Prompt-SemiBold;
  font-size: 26px;
  line-height: 0.92;
  letter-spacing: normal;
  color: #1b2125;
}

.teacher-price-text{
	flex-grow: 0;
	font-family: Prompt-SemiBold;
	font-size: 13px;
	line-height: 0.92;
	letter-spacing: normal;
	color: #1b2125;
}

.buscados-button{
  flex-grow: 0;
  font-family: Outfit;
  font-size: 15px;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  transition: all 0.3s ease;
  height: 43px;
  padding-left: 30px;
  padding-right: 30px;
}

.buscados-button:hover{
   color:#fff;
}

.buscados-button{
	--bs-btn-active-color: #fff;
}

.buscados-spinner-button{
	width: 24px;
	height: 24px;
	flex-grow: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 4px;
	border: solid 1px #3f4651;
	background-color: #fff;
	color: #3f4651 !important;
	font-size: 21px;
    font-family: 'Outfit';
	transition: all 0.3s ease;
	&:hover {
		opacity: 0.6;
	}
}

.pb-less{
	padding-bottom: 4px;
}

.buscados-shopping-cart{
  width: 19px;
  height: 19px;
  margin-right: 10px;
}


.portada-intermedia-div{
	width: 90%;
	padding: 10px 90px;
	min-height: 466px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: end;
	border-radius: 10px;
	background-size: cover;
	background-image: url('/img/portada-intermedia.webp');
	margin-left: auto;
	margin-right: auto;
	margin: 100px auto;

}

.portada-intermedia-text{
	font-family: Outfit-Bold;
	font-size: 39px;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: left;
	color: #fff;
	max-width: 460px;
}

.portada-intermedia-btn-container {
	margin-top: 35px;
}

.portada-intermedia-button{
	display: initial;
	height: 43px;
	flex-grow: 0;
	gap: 10px;
	padding: 12px 24px;
	border-radius: 4px;
	border: solid 2px #fff;
	font-family: Outfit-SemiBold;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #fff;
	transition: all 0.3s ease;
	&:hover {
		background-color: #fff;
		color: #3f4651;
		cursor: pointer;
	}
}

/*Home categorias*/
.home-categorias{
	margin-top: 160px;
	width: 85%;
	margin-right: auto;
	margin-left: auto;
}

.home-categorias .titulo{
	margin: 0 0 16px;
	font-family: Playfair Display;
	font-size: 36px;
	font-style: normal;
	line-height: 1.03;
	letter-spacing: normal;
	text-align: center;
	color: #000;
}



.card-categoria{
	height: 117px;
	border-radius: 20px;
	box-shadow: 0 6px 28px 0 rgba(0, 0, 0, 0.1);
	background-color: #fff;
	margin: auto 10px;
	font-family: Prompt;
	font-size: 18px;
	line-height: 1.2;
	letter-spacing: normal;
	color: #000;
	cursor: pointer;
	text-decoration: none;
	transition: all 0.3s ease;
	&:hover {
        box-shadow: 0 6px 28px 0 rgba(0, 0, 0, 0.2);
        transform: translateY(-10px);
    }
}
/*Editoriales*/
.home-editoriales{
	width: 90%;
	margin: 100px auto;
}


.home-editoriales .titulo{
	margin: 0 0 16px;
	font-family: Playfair Display;
	font-size: 36px;
	line-height: 1.03;
	letter-spacing: normal;
	text-align: center;
	color: #000;
}
.home-editoriales .card-grande {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 118px 0;
  border-radius: 8px;
  background-color: #000;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.7;
  }
}

.home-editoriales .card-small{
  width: 100%;
  flex-grow: 0;
  padding: 43px 32.5px 43px 32.5px;
  border-radius: 8px;
  background-color: #000;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.7;
  }
  height: 200px;
}

.home-editoriales .logo-grande {
  width: 184px;
  height: 43px;
  align-self: center;
  flex-grow: 0;
}

.home-editoriales .logo-small {
	display: block;
	max-width: 60%;
	max-height: 70px;
}

.div-home-editorial-card a{
	text-decoration: none;
	color: white;
	font-size: 30px;
	font-weight: bold;
	font-family: PlayfairDisplay-SemiBold;
}

/*resultados*/

.b-pagination li.page-item button.page-link {
  color: black;
}

.b-pagination li.page-item:not(.active) button.page-link {
  opacity: 0.5;
}

.b-pagination li.page-item.active button.page-link {
 background-color: white;
  border-color: black;
}

.listado-resultados{
  margin-left: auto;
  margin-right: auto;

}

/*editoriales*/
.editorial-titulo {
  margin-left:5%;
  font-family: Playfair Display;
  font-size: 40px;
  line-height: 0.93;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.breadcrumb{
   margin-left:5%;
}

.breadcrumb-item + .breadcrumb-item::before{
  display: none;
}
.breadcrumb-item.active span{
  text-decoration: none;
  flex-grow: 0;
  font-family: Prompt;
  font-size: 14px;
  line-height: 1.9;
  letter-spacing: normal;
  color: #afb5bf;
}


.breadcrumb-next{
  color: #afb5bf
}

.breadcrumb-a{
  text-decoration: none;
  flex-grow: 0;
  font-family: Prompt-SemiBold;
  font-size: 14px;
  line-height: 1;
  letter-spacing: normal;
  color: #3f4651;
}

.cabecera-imagen-editorial{
	height: var(--altura-img-editorial-info);
	width: 100%;
	position: initial;
	top: 0;
	background-size: cover;
	background-image:
        linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        url('/img/editorial-header.webp');
    padding: 71px;
}



/*categorias*/
.catalogo-categorias{
  margin-left: auto;
  margin-right: auto;
}

.card-catalogo{
	height: 67px;
	margin-right: 15px;
	font-family: Prompt;
	font-size: 14px;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: left;
	color: #000;
	flex-grow: 0;
	padding: 25px;
	border-radius: 6px;
	box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.08);
	background-color: #fff;
	display: inline-block;
	cursor: pointer;
	text-decoration: none;
	transition: all 0.3s ease;
	&:hover {
        box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.12);
        transform: translateY(-10px);
    }
}

.card-catalogo.selected{
  border: solid 1px #ffc602;
}

.catalogo-categorias .logo-categorias{
  width: 17px;
  height: 17px;
}

.catalogo-categorias .logo-categorias{
  margin-right: 8px;
}
.catalogo-categorias .div-select{
 margin-right: 5px;
}

.catalogo-categorias .div-select select{
 border:0;
 border-radius: 4px;
}

.catalogo-categorias .form-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffc602' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.resumen-carrito{
  margin-left: 5%;
  margin-right: 5%;
  min-height: 55vh;
}


.resumen-carrito .resumen-pedido{
	padding-left: 0;
	flex-grow: 0;
	gap: 36px;
	padding: 39px 36px 27px;
	border-radius: 11px;
	box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.05);
	background-color: #fff;
	margin-right: auto;
	width: 67%;

	@media (max-width: 600px) {
		padding: 39px 25px 27px;
	}
}

.resumen-pedido .title{
	  flex-grow: 0;
	  font-family: Outfit-Bold;
	  font-size: 12px;
	  line-height: normal;
	  letter-spacing: 2px;
	  text-align: left;
	  color: #c3c6ca;
	  text-transform: uppercase;

}

.titleMiCuenta{
	flex-grow: 0;
	font-family: Outfit-Bold;
	font-size: 12px;
	line-height: normal;
	letter-spacing: 2px;
	text-align: left;
	color: #c3c6ca;
	text-transform: uppercase;

}

.resumen-carrito .resumen-gastos{
	flex-grow: 0;
	display: flex;
	flex-direction: column;
	gap: 14px;
	padding: 30px;
	border-radius: 8px;
	box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.05);
	background-color: #fff;
	margin-left: auto;
	width: 30%;
	height: fit-content;
}

.resumen-carrito .portada {
	width: 84.6px;
	border-radius: 8px;
}

.resumen-pedido .buscados-portada{
	text-align: center;
}

.resumen-pedido .card{
	border: 0;
}

.resumen-carrito .div-trash {
  width: 34.4px;
  height: 36px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 1px 8px;
  border-radius: 70px;
  border: solid 1px #e2e2e2;
}

.resumen-carrito .unidades{
  font-family: Outfit-Medium;
  font-size: 16px;
  color: #afb5bf;
}

.resumen-carrito .precio{
  font-family: Outfit-SemiBold;
  font-size: 18px;
  color: #3f4651;
}

.resumen-carrito .trash-icon{
 cursor: pointer;
}

.resumen-carrito .total{
  width: 43px;
  height: 22px;
  flex-grow: 0;
  font-family: Outfit-SemiBold;
  font-size: 17px;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #afb5bf;
}

.resumen-carrito .text-total{
  flex-grow: 0;
  font-family: Outfit-Medium;
  font-size: 17px;
  line-height: 1.2;
  letter-spacing: normal;
  color: #afb5bf;
}

.resumen-carrito .total-precio{
  flex-grow: 0;
  font-family: Outfit-SemiBold;
  font-size: 22px;
  line-height: normal;
  letter-spacing: normal;
  color: #3f4651;
}

.resumen-carrito .resumen-gastos .total-text{
  font-family: Outfit-Medium;
  font-size: 21px;
  color: #3f4651;
}


.resumen-carrito .resumen-gastos .total-precio{
  flex-grow: 0;
  font-family: Outfit-SemiBold;
  font-size: 24px;
  color: #3f4651;
}

.resumen-carrito .resumen-gastos .tipo{
  font-family: Outfit-Medium;
  font-size: 17px;
  color: #afb5bf;
}


.resumen-carrito .resumen-gastos .precios{
  font-family: Outfit-SemiBold;
  font-size: 20px;
  color: #3f4651;
}

.resumen-carrito .resumen-gastos .question-cupon{
  font-family: Outfit-Medium;
  font-size: 12px;
  text-decoration: underline;
  color: #afb5bf;
}

.resumen-carrito .resumen-gastos .active .div-cupon-dropdown{
	display: flex;
}
.resumen-carrito .resumen-gastos .div-cupon-dropdown{
	display: none;
}

.resumen-carrito .resumen-gastos .input-cupon{
	height: 100%;
	font-family: Outfit;
	font-size: 11px;
	color: #a9adb4;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.resumen-carrito .resumen-gastos .input-cupon::placeholder{
	font-family: Outfit;
	font-size: 11px;
	color: #a9adb4;
}

.resumen-carrito .resumen-gastos .active .cupon-dropdown{
	transform: rotate(180deg);
}

.resumen-carrito .resumen-gastos .div-cupon-input{

}

.resumen-carrito .resumen-gastos .div-enviar{
	padding: 0;

}

.resumen-carrito .error-cupon {
  color: red;
  font-family: Outfit;
  font-size: 12px;
}

.resumen-carrito .div-delete-cupon{
  margin-left: auto;
}

.resumen-carrito .cupon-text{
  font-family:Prompt;
  font-size: 16px;
}

.resumen-carrito .resumen-gastos .div-enviar button{
	width: 100%;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}


.resumen-carrito .resumen-gastos .avanzar-text {
  font-family: Outfit-SemiBold;
  font-size: 15px;
  color: #fff;
  margin-right: 5px;
}

.resumen-carrito .avanzar-compra{
	margin-left: auto;
	margin-right: auto;
}


/*FICHA*/
.ficha-libro{

}

.ficha-libro .titulo-back{
	margin-left: 5%;
}

.ficha-libro .icon-back{

	width: 24px;
	height: 24px;
}

.ficha-libro .titulo-back-text{
  font-family: Prompt-SemiBold;
  font-size: 22px;
  color: #1d1f21;
  margin-left: 15px;

  @media (max-width: 768px) {
	font-size: 16px;
  }

}

.ficha-libro .obra{

}

.ficha-libro .titulo{
  font-family: Playfair Display;
  font-size: 40px;
  color: #000;
}

.ficha-libro .autor{
	font-family: Prompt-SemiBold;
	font-size: 22px;
  	line-height: 1.27;
	color: #979797;
}

.ficha-libro .info-extra{
  font-family: Prompt;
	font-size: 18px;
  color: #1d1f21;
  font-weight: 600;
}

.ficha-libro .isbn{

}

.ficha-libro .cod-com{

}

.ficha-libro .paginas{

}

.ficha-libro .editorial{

}

.ficha-libro .coleccion{

}

.ficha-libro .visores{

}

.ficha-libro .precio{
  font-size: 40px;
  font-family: Prompt-Bold;
  color: #1b2125;
}
.ficha-libro .cantidad{

}

.ficha-libro .div-add-button{

}

.ficha-libro .relacionadas{

}

.ficha-libro .titulo-relacionadas{

  font-family: Playfair Display;
  font-size: 36px;
  color: #000;
}

/*EDITORIALES*/

.editoriales .w-20{
	width: 20%;
}

.editoriales .titulo-editoriales{
	width: 100%;
	text-align: center;
	font-size: 36px;
	font-family: Playfair Display;
	color: #000;
}

.border-bottom {
    width: 6%;
    height: 5px; /* Altura máxima del borde */
    background-color: #ffc602; /* Cambiar el color según tus necesidades */
    margin-right: auto;
    margin-left: auto;
}


.editoriales .editoriales-texto {
	margin-right: 5%;
	margin-left: 5%;
}
.editoriales .editoriales-texto .container{
	width: 100%;
	height: fit-content;
	background-color: black;
	padding-left: 0;
	border-radius: 5px;
	border-bottom-left-radius: 10%;
	padding-top: 4%;
	padding-right: 8%;
	position: relative;
}

.editoriales .editoriales-texto .portada{
	width: 100%;
	object-fit: cover;
	border-radius: 5px;
	padding-top: 1%;
	margin: auto;
	align-self: start;
	padding-left: 0;
}

.editoriales .editoriales-texto .nombre{
	width: 85%;
	height: 60px;
	padding: 0;
	background-color: #000;
	border-radius: 10px;
	position: absolute;
	display: block;
	right: 0;
	margin-left: auto;
	margin-right: 6%;
	bottom: 10%;
	color: white;
    font-weight: bold;
}

.editoriales .logo-editorial{
	  width: 97px;
}


.editoriales .titulo-editoriales-nuestras{
  font-family: Playfair Display;
  font-size: 36px;
  text-align: center;
  color: #000;
}

.editoriales .webs-oficiales{
	margin-right: 10%;
	margin-left: 10%;
}


.webs-oficiales .width{
	width: 18%;
}

.webs-oficiales .nombre-editorial{
	width: 100%;
	left: 0;
	text-align: center;
	font-family: Outfit-Medium;
	font-size: 13px;
	color: #454545;
	text-transform: uppercase;
}

.webs-oficiales .card-image{
	float: left;
	margin-bottom: 12px;
	overflow: hidden;
	position: relative;
	text-align: center;
	width: 100%;
	border-radius: 10px;
}



.webs-oficiales .card-logo{
	background: #FFFFFF;
	height: 100px;
	left: 50%;
	position: absolute;
	top: 50%;-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 100px;
	z-index: 100;
}

.webs-oficiales .editorial-logo{
	left: 50%;
	position: absolute;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 100px;
}


.factura-text{
  font-family: Outfit-Bold;
  font-size: 17px;
  text-align: left;
  color: #3f4651;
}


#listado-productos-paso-2 .listado-productos{
  display: none;
}


#listado-productos-paso-2.active .listado-productos{
  display: block;
}

.min-height-0{
  min-height: 0;
}

#deploy-product-list{
	width: 30px;
	margin-bottom: 6px;
	cursor: pointer;
	transform: rotate(180deg);
}


#listado-productos-paso-2.active #deploy-product-list{
	transform: rotate(0);
}


.resumen-carrito .aviso-pago{
  font-family: Outfit-Regular;
  font-size: 11px;
  color: #afb5bf;
}

.credit-card{
	width: 27px;
}


/*MI CUENTA*/
.mi-cuenta{
	margin-top: 60px;
	margin-bottom: 60px;
}


.mi-cuenta .tarjeta{
	background-color: #fff;
  	padding: 30px;
  	border-radius: 8px;
  	box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.05);
}

.mi-cuenta-header-title{
  font-family: PlayfairDisplay-SemiBold;
  font-size: 50px;
  color: #fff;
  margin-top: calc( (var(--altura-img-cuenta)) * 0.48);
}

.mm-cuenta {
	margin-top: -45px !important;
	.enlace-mi-cuenta{
		min-height: 78px;
		transition: all 0.3s ease;
		&:hover {
			box-shadow: 0 6px 28px 0 rgba(0, 0, 0, 0.15);
        	transform: translateY(-10px);
		}
	}
}

.menu-mi-cuenta{
  position: relative;
  margin-top: -55px;
}

.menu-mi-cuenta a{
  text-decoration: none;
}

.enlace-mi-cuenta{
  min-height: 110px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  font-family: Outfit-ExtraBold;
  font-size: 14px;
  color: #1b2125;
  width: 100%;
}

.enlace-mi-cuenta.selected{
	color: #ffc602;
	border: solid 2px #ffc602;
}

.mi-cuenta .botones .border-button{
	border: solid 1px #3f4651;
}

.mi-cuenta .botones button {
  font-family: Outfit-SemiBold;
  font-size: 15px;
  color: #1b2125;
  height: 43px;
}

.mi-cuenta .botones button.btn-guardar{
  color:#fff;
  margin-left: 56px;
}


.mi-cuenta .botones button.btn-guardar:hover{
  color:#fff;
  background-color:  rgba(255, 198, 5, 0.8);
}

.mi-cuenta .custom-checkbox input[type="checkbox"]:checked + .checkmark::after {
  top: 0px;
}
.school-search{
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-right: 0;
}

.school-button-search{
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	display: flex;
    gap: 10px;
    align-items: center;
    padding: 5px 20px;
    color: white;

	&:hover {
		color: white;
	}
}

/*Mis PEDIDOS*/
.mis-pedidos{
	margin-top: 60px;
	margin-right: 8%;
	margin-left: 8%;
}


.mis-pedidos .pedido{
  gap: 665px;
  padding: 23px;
  border-radius: 11px;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
}
.mis-pedidos .n-pedido{
  display: flex;
  flex-direction: row;
  width: fit-content;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
  border-radius: 30px;
  background-color: rgba(255, 198, 2, 0.26);
  font-family: Outfit-Bold;
  font-size: 12px;
  color: #3f4651;
}


.mis-pedidos .fecha-pedido {
  font-family: Outfit-Regular;
  font-size: 14px;
  color: #afb5bf;
}

.mis-pedidos .text-total{
  font-family: Outfit-Medium;
  font-size: 17px;
  color: #afb5bf;
}

.mis-pedidos .total-precio {
  font-family: Outfit-SemiBold;
  font-size: 22px;
  color: #3f4651;
}

.mis-pedidos .img-product-list{
  width: 30px;
  margin-bottom: 6px;
  cursor: pointer;
  transform: rotate(180deg);
}

.mis-pedidos .active .img-product-list {
  transform: rotate(0);
}

.mis-pedidos .listado-productos{
  display: none;
}


.mis-pedidos .active .listado-productos{
  display: block;
}

.mis-pedidos .buscados-card-titulo{
  font-family: Outfit-SemiBold;
  font-size: 20px;
  color: #3f4651;
}

.mis-pedidos .buscados-card-colec{
  font-family: Outfit-Regular;
  font-size: 14px;
  color: #afb5bf;
}

.mis-pedidos .buscados-card-editorial{
  font-family: Outfit-Regular;
  font-size: 14px;
  color: #afb5bf;
}

.mis-pedidos .ficha-libro .unidades{
  font-family: Outfit-Medium;
  font-size: 16px;
  color: #afb5bf;
}

.mis-pedidos .ficha-libro .precio{
  font-family: Outfit-SemiBold;
  font-size: 16px;
  color: #3f4651;
}

.mis-pedidos .ficha-libro .card{
  border: 0;
}

.mis-pedidos hr {
	height: 1px;
	flex-grow: 0;
	background-color: rgba(224, 229, 237, 0.3);
	width: 96%;
	margin-right: auto;
	margin-left: auto;
}


.descargas-licencias .card{
	height: 100%;
}

.descargas-licencias div.portada{
	margin-top: -4rem;
	display: flex;
    justify-content: center;
}

.descargas-licencias  img.portada{
	width: 90%;
	max-width: 250px;
}

.descargas-licencias .portada img:hover{
	opacity: initial;
}

.descargas-licencias .titulo{
  font-family: Prompt-SemiBold;
  font-size: 22px;
  color: #1d1f21;
}

.descargas-licencias .licencia-o-descarga{
  font-family: Outfit-Regular;
  font-size: 16px;
  color: #afb5bf;
}

.descargas-licencias .codigo{
  font-family: Outfit-Regular;
  font-size: 16px;
  color: #afb5bf;
}

.descargas-licencias .precio{
  font-family: Prompt-SemiBold;
  font-size: 26px;
  color: #1b2125;
}


.no-informacion{
	font-family: Outfit;
	font-size: 20px;
}

/*ALERTAS*/
#alert .icon img{
    width: 46.7px;
    height: 46.7px;
}

#alert .message{
  font-family: Prompt-Medium;
  font-size: 28px;
  color: #000;
  text-align: center;
}

#alert .button-login-text{
  font-family: Outfit-SemiBold;
  font-size: 15px;
  color: #fff;
}

#alert .registro-modal{
  padding: 30px;
}


/*RESUMEN COMPRA*/

.resumen-compra .gracias-text{
  font-family: Outfit-SemiBold;
  font-size: 31px;
  color: #3f4651;
}


.resumen-compra .pedido-correcto {
  font-family: Outfit-SemiBold;
  font-size: 22px;
  color: #3f4651;
}

.resumen-compra .title{
  font-family: Outfit-Bold;
  font-size: 12px;
  color: #c3c6ca;
}


.resumen-compra .datos {
  font-family: Outfit-SemiBold;
  font-size: 17px;
  color: #3f4651;
}

.resumen-carrito .cabecera-listado .title{
  font-family: Outfit-Bold;
  font-size: 12px;
  color: #c3c6ca;
  text-transform: uppercase;

}

.resumen-carrito .listado-obras .card{
  border: 0;
}

.resumen-carrito .listado-obras .card .precio{
  font-family: Outfit-SemiBold;
  font-size: 20px;
  color: #3f4651;
}

.resumen-carrito .subtotal-text{
  font-family: Outfit-Medium;
  font-size: 17px;
  color: #afb5bf;
}

.resumen-carrito .subtotal .precio{
  font-family: Outfit-SemiBold;
  font-size: 24px;
  color: #3f4651;
}


.resumen-carrito .n-pedido{
  width: fit-content;
  padding: 10px 20px;
  border-radius: 30px;
  background-color: rgba(255, 198, 2, 0.26);
  font-family: Outfit-Bold;
  font-size: 12px;
  color: #3f4651;
}

.resumen-compra .listado-obras .card .precio{
  font-family: Outfit-SemiBold;
  font-size: 16px;
  color: #3f4651;
}

.resumen-compra .unidades {
  font-family: Outfit-Medium;
  font-size: 16px;
  color: #afb5bf;
}

.warning-icon-self{
	color: #ffffff;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 3px 7px 4px;
	border: 0;
	background-color: #ffc605;
	color: #000000 aliceblue !important;
	font-size: 27px;
	margin-right: 13px;
}



/*ERROR COMPRA*/
.error-compra{
	min-height: 60vh;
	margin-right: 35%;
	margin-left: 35%;
	align-items: center;
}

.error-compra .title {
  font-family: Outfit-Bold;
  font-size: 31px;
  color: #3f4651;
}

.error-compra .texto{
  font-family: Outfit;
  font-size: 18px;
  color: #939393;
}

.error-compra .texto .email{
  font-family: Outfit-Medium;
  color: #000000;
}

/*AYUDA*/
.cabecera-imagen-ayuda{
	height: var(--altura-img-ayuda);
	width: 100%;
	position: relative;
	margin-top: -120px;
	background-size: cover;
	background-image:
        linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        url('/img/ayuda-header.webp');
    padding: 71px;
}

.ayuda .enlace-mi-cuenta {
  cursor: initial;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
}

.ayuda .menu-title{
  font-family: Outfit-Semibold;
  font-size: 12px;
  color: #ffc602;
  text-transform: uppercase;
}



.ayuda .menu-text {
  font-family: Outfit-SemiBold;
  font-size: 17px;
  color: #3f4651;
}


.preguntas{
	margin-top: 75px;
	//margin-bottom: 120px;
}

.ayuda .preguntas .title{
  font-family: Prompt;
  font-size: 36px;
  color: #000;
}

.ayuda .card-ayuda{
  width: 100%;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
}

.ayuda .card-ayuda .title{
  font-size: 20px;
  font-family: Outfit-Semibold;
}

.ayuda .card-ayuda .respuesta-texto{
  display: none;
}

.ayuda .card-ayuda.active .respuesta-texto{
  display: block;
}

.ayuda .card-ayuda img{
  width: 23px;
  height: 23px;
  cursor: pointer;
}

.ayuda .card-ayuda img.minus{
 display: none;
}


.ayuda .card-ayuda img.plus{
  display: initial;
}


.ayuda .card-ayuda.active img.minus{
  display: initial;
}


.ayuda .card-ayuda.active img.plus{
   display: none;
}

.ayuda .card-ayuda .respuesta-texto {
	font-family: Outfit;
	font-size: 20px;
	color: #3f4651;
	margin-top: 20px;
}

.ayuda #problema{
	width: 100%;
	height: 247px;
}

.cuadernos-vacaciones {

	.card-catalogo {
		height: 45px;
		min-width: 100px;
		font-weight: bold;
		padding: 0;
		justify-content: center;
	}
	.card-catalogo-main{
		height: 75px;

	}

	.header {
		position:relative;
		text-align:center;
		width: 100%;
		position: relative;
		background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(/img/cuadernos-vacaciones-header.webp);
		background-size:     cover;                      /* <------ */
		background-repeat:   no-repeat;
		background-position: center center;
		color:white;
	  }

	  .inner-header {
		height: 300px;
		width:100%;
		margin: 0 auto;
		padding: 0;
	  }

	  .flex { /*Flexbox for containers*/
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
	  }

	  .waves {
		position:relative;
		width: 100%;
		height: 9vh;
		min-height:100px;
		max-height:150px;
	  }

	  .content {
		position:relative;
		height:20vh;
		text-align:center;
		background-color: white;
	  }

	  /* Animation */

	  .parallax > use {
		animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
	  }
	  .parallax > use:nth-child(1) {
		animation-delay: -2s;
		animation-duration: 14s;
	  }
	  .parallax > use:nth-child(2) {
		animation-delay: -3s;
		animation-duration: 20s;
	  }
	  .parallax > use:nth-child(3) {
		animation-delay: -4s;
		animation-duration: 26s;
	  }
	  .parallax > use:nth-child(4) {
		animation-delay: -5s;
		animation-duration: 40s;
	  }
	  @keyframes move-forever {
		0% {
		 transform: translate3d(-90px,0,0);
		}
		100% {
		  transform: translate3d(85px,0,0);
		}
	  }
	  /*Shrinking for mobile*/
	  @media (max-width: 768px) {
		.waves {
		  height:40px;
		  min-height:40px;
		}
		.content {
		  height:30vh;
		}
		h1 {
		  font-size:24px;
		}
	  }
}

.cuadernos-vacaciones-header-title {
	font-family: PlayfairDisplay-SemiBold;
    font-size: 50px;
    color: #fff;
    line-height: normal;
	p {
		font-size: 20px;
		font-family: Prompt;
		text-align: left;
	}
}


/*POLITICAS*/
.politicas-pagina{
	min-height: 60vh;
	margin-right: 10%;
	margin-left: 10%;
}

.politicas-pagina .title{
  font-family: Prompt;
  font-size: 40px;
  color: #000;
}

.politicas-pagina .texto{
  font-family: Outfit;
  font-size: 20px;
  color: #3f4651;
}


html {
  min-height: 100%;
  position: relative;
}

body {
  margin: 0;
}


/*FOOTER*/

footer{
	background-color: #000;
  	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 30px 70px;
	height: 186.9px;
	bottom: 0;

	@media (max-width: 900px) {
		height: auto;
		padding: 20px 70px;

		.ga-footer{
			margin-top: 1.5rem;
		}
	}

	.anaya-text{
		color: #fff
	}

	.derechos{
	  flex-grow: 0;
	  font-family: Prompt ;
	  font-size: 14px;
	  line-height: 1.14;
	  letter-spacing: normal;
	  text-align: left;
	  color: #828282;
	  align-self: end;
	}

	.text{
		font-family: Outfit ;
  		font-size: 14px;
		line-height: 1.14;
		letter-spacing: normal;
		text-align: left;
		color: #828282;
	}

	.politicas{
	  flex-grow: 0;
	  text-align: left;
	  align-self: end;
	}

	.sobre-icon{
	  width: 25px;
	  margin-right: 6px;
	}

	.telefono-icon{
		  height: 20px;
		  margin-right: 6px;
	}

	.contacto{
	  flex-grow: 0;
	  display: flex;
	  justify-content: flex-end;
	  gap: 32px;
	  padding: 0;

		@media (max-width: 768px) {
			justify-content: center;
		}
	}

	a {
		color: #828282;
		text-decoration: underline;
	}

	.logo-footer-mobile{
		width: auto;
		height: 37px;
	}

	.logo-footer-desktop{
		height: 37px;
	}

	.ga-footer {
		background-color: #000;
		color: white;
		padding: 10px 20px;
	/* padding: 25px 20px;*/
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		gap: 35px 40px;

		@media (max-width: 768px) {
			justify-content: center;
		}
	}

	.ga-footer a {
		transition: all 0.3s ease;
	}

	.ga-footer a:hover {
		opacity: 0.75;
	}

	.ga-footer .ga-footer-legal {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 15px 35px;
	}

	.ga-footer .ga-footer-legal a.ga-footer-link{
		font-family: Arial, Helvetica, sans-serif !important;
		color: white;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 14px;
	}

	.ga-footer .ga-footer-icons {
		display: flex;
		justify-content: center;
		gap: 10px 35px;

		@media (max-width: 768px) {
			margin: 0 auto;
		}
	}

	.ga-footer .ga-footer-icons a.ga-footer-icon{
		display: block;
	}

	.ga-footer .ga-footer-icons a.ga-footer-icon img{
		height: 20px;
	}
}

/*CARD OBRA*/
.mtmin-2{
	margin-top: max(0.5rem, 5px);
}
// Añade Ángel

.card {
	padding: 18px 26px !important;
  	border-radius: 8px;
  	box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.05);
	border: none;
	.buscados-button {
		height: 43px;
	}
}

.home-mas-buscados {
	.card {
		height: 100%;
	}
}

.input-cantidad {
	font-family: Outfit-SemiBold;
	color: #3f4651;
}

.listado-resultados.relacionadas {
	margin: 100px auto;
}


.ficha-portada-img {
	box-shadow: 0 6px 28px 0 rgba(0, 0, 0, 0.1);
	border-radius: 10px;
}

.pagination {
	margin-bottom: 60px;
	.page-item button,
	.page-item span {
		min-width: 30px;
  		height: 30px;
		font-family: Outfit;
		font-size: 14px;
		border-radius: 4px;
		color: #afb5bf !important;
  		border: solid 1px #afb5bf !important;
  		background-color: #fff !important;
		margin: 0 4px !important;
		opacity: 1 !important;

	}
	.page-item {
		transition: all 0.3s ease;
		&.active button,
		&.active span{
			color: #1d1f21 !important;
			border: solid 1px #1d1f21 !important;
		}
		&:first-child button,
		&:first-child span,
		&:last-child button,
		&:last-child span{
			line-height: 10px;
			color: #fff !important;
			background-color: #1d1f21 !important;
			border: solid 1px #1d1f21 !important;
		}
	}
}

.listado-productos {
	.no-product {
		font-weight: 300;
		font-family: 'Outfit';
		font-size: 14px;
		color: #000;
	}
}

input:focus-visible {
    border-color: #ffc602 !important;
	outline: #ffc602 !important;
}

.resumen-compra .buscados-card-titulo {
  font-family: Outfit-SemiBold;
  font-size: 20px;
  color: #3f4651;
}

.resumen-compra .ficha-libro .precio {
  font-family: Outfit-SemiBold;
  font-size: 16px;
  color: #3f4651;
}


.d-only-mobile{
	display: none;
}

/*DIGITAL O PAPEL*/
.dDigital{
	margin-left: 20px;
    border: 2px solid #ED7070;
    border-radius: 14px;
    color: #ED7070;
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
}
.dCarritoDigital{
    border: 1px solid #ED7070;
    border-radius: 14px;
    color: #ED7070;
    padding: 2px;
	width: fit-content;
	padding-left: 8px;
	padding-right: 8px;
}
.dResumenDigital{
	width: 85px;
    border: 2px solid #ED7070;
    border-radius: 14px;
    color: #ED7070;
	margin-right: 20px;
	padding-bottom: 2px;
}
.dFichaObraDigital{
	border: 2px solid #ED7070;
    border-radius: 14px;
    color: #ED7070;

	.txtDigital{
		font-size: 18px;
		font-weight: bold;
		margin-top: 4px;
		margin-left: 7px;
	};
}
.dFichaObraPapel{
	border: 2px solid #54A4D5;
    border-radius: 14px;
    color: #54A4D5;

	.txtPapel{
		font-size: 18px;
		font-weight: bold;
		margin-top: 4px;
		margin-left: 7px;
	};
}

.dFichaObraDigital, .dFichaObraPapel{
	min-width: 110px;
    width: fit-content;
    padding: 5px 10px;
	border-radius: 50px;

	img{
		height: 20px;
		width: auto;
		vertical-align: text-bottom;
	}
}

.txtDigital{
	font-size: 12px;
    font-weight: 100;
    margin-top: 3px;
    margin-left: 7px;
}
.txtCarritoDigital{
	font-size: 10px;
    font-weight: 80;
}
.txtResumenDigital{
	font-size: 13px;
    font-weight: 600;
    margin-top: 3px;
    margin-left: 7px;
}
.dPapel{
	margin-left: 20px;
    border: 2px solid #54A4D5;
    border-radius: 14px;
    color: #54A4D5;
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
}
.dCarritoPapel{
    border: 1px solid #54A4D5;
    border-radius: 14px;
    color: #54A4D5;
    padding: 2px;
	width: fit-content;
	padding-left: 8px;
	padding-right: 8px;
}
.dResumenPapel{
	width: 85px;
    border: 2px solid #54A4D5;
    border-radius: 14px;
    color: #54A4D5;
	margin-right: 20px;
	padding-bottom: 2px;
}

.txtPapel{
	font-size: 12px;
    font-weight: 100;
    margin-top: 4px;
    margin-left: 7px;
}
.txtCarritoPapel{
	font-size: 10px;
    font-weight: 80;
}
.txtResumenPapel{
	font-size: 13px;
    font-weight: 600;
    margin-top: 4px;
    margin-left: 7px;
}



/* Media query */
@media (max-width: 1200px) {
	.d-only-desktop{
		display: none !important;
	}

	.compra-paso-2 .resumen-carrito .resumen-pedido .buscados-card-titulo{
		font-size: 13px;
	}

	.home-editoriales .card-small {
	  height: 120px;
	}

	.div-home-editorial-card a{
		font-size: 20px;
	}

	.resumen-carrito .resumen-gastos{
		width: 100%;
	}

	.resumen-carrito .resumen-pedido{
		width: 100%;
	}

	.div-cupon-dropdown{
		margin-left: auto;
	}

	.resumen-carrito .resumen-pedido  .unidades{
		margin-right: auto;
	}

	#resumenGastosLoginBT{
		width: fit-content;
	}
	.avanzar-compra{
		width: fit-content;
	}

	.error-compra{
		margin-right: 10%;
		margin-left: 10%;
	}

}

@media (max-width: 1033px) {
	.lupa-icon{
		margin-right: 0px;
	}

	.text-buscar-buscador{
		font-size: 11px;
	}
}

@media (max-width: 992px) {

	.filtros-catalogo {
	  display: flex;
	  flex-wrap: wrap;
	  max-width: 100vw; /* Ancho máximo del dispositivo */
	}

	.filtros-catalogo > * {
	  margin: 5px; /* Espacio entre elementos */
	}

	.ficha-libro .precio {
	  font-size: 20px;
	}

	.text-buscar-buscador{
		display: none;
	}

	.input-ppal{
		font-size: 12px !important;
	}

	.ficha-libro .titulo {
		font-size: 35px;
	}

	header .popover-menu {
	  right: 0;
	}

	.mm-cuenta .enlace-mi-cuenta {
		font-size: 12px;
	}

	.webs-oficiales .width {
		margin-top: 1rem;
		margin-bottom: auto !important;
	}

	.resumen-carrito .resumen-pedido .card-obra{
		padding: 18px 0px !important;
	}

	.resumen-carrito .resumen-pedido .buscados-card-titulo{
		font-size: 13px;
	}


	.resumen-carrito .resumen-pedido .precio-paso-1{
		margin-top: 15px;
		padding-right: calc(var(--bs-gutter-x) * 1);
		padding-left: calc(var(--bs-gutter-x) * 1);
	}

	.home-editoriales {
	  margin: 20px auto;
	}

	.div-home-editorial-card a{
		font-size: 13px;
	}

	.editorial-titulo {
	  font-size: 23px;
	}

	.br-registro {
	  border-right: none;
	  padding-right: calc(var(--bs-gutter-x) * 0.5);
	}

	.br-registro-right{
		padding-left: calc(var(--bs-gutter-x) * 0.5);
	}
}




@media (max-width: 600px) {
	.d-only-mobile{
		display: block;
	}

	.d-non-mobile{
		display: none !important;
	}

	.home-header-text {
		font-size: 21px;
	}

	.portada-intermedia-text{
		font-size: 19px;
	}

	.cabecera-imagen{
		padding-left: 20px;
		padding-right: 20px;
		justify-content: center;
		height: var(--altura-img-home-mobile);
  	}

	.home-header-title  {
 		margin-top: var(--altura-header);
  	}

  	.buscador-general{
  		padding: 60px 10px 60px 10px;
  	}

  	.text-buscar-buscador{
  		display: none;
  	}

	.ficha-libro .titulo {
	  font-size: 20px;
	}

	.ficha-libro .autor {
	  font-size: 12px;
	}

	.ficha-libro .info-extra {
	  font-size: 12px;
	}

	header .popover-menu {
	  width: 80vw;
	  right: 0;
	}

	.carrito-list {
	  width: 90Vw;
	  right: -70px;
	}

	.carrito-text-compra{
		display: none;
	}

	.br-mobile-0{
		border-radius: 0;
	}

	.br-mobile-bottom{
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	.br-mobile-top{
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	/*MI CUENTA*/
	.mm-cuenta .enlace-mi-cuenta {
		justify-content: center;
		align-items: center;
		min-height: 47px;
		padding: 6px;
	}

	.mis-pedidos .listado-productos .card, .resumen-compra  .listado-obras .card{
		padding: 18px 8px !important;
	}


	.mis-pedidos .listado-productos .buscados-card-titulo, .resumen-compra .buscados-card-titulo{
		font-size: 15px;
	}
	.resumen-compra  .buscados-card-titulo{
		font-family: Outfit-SemiBold;
	}

	.mis-pedidos .total-precio {
	  font-size: 17px;
	}

	.mis-pedidos .text-total {
	  font-size: 14px;
	}

	.mis-pedidos .listado-productos .card .unidades{
		text-align: start !important;
	}

	.div-unidades-ficha-pedido{
		margin-top: 15px;
	}

	.mis-pedidos .listado-productos .div-total{
		align-self: start;
	}

	.carrito-list .subtotal .subtotal-text {
	  font-size: 13px;
	}

	.carrito-list .subtotal .cantidad {
	  font-size: 14px;
	}

	/*EDITORIALES*/
	.editoriales .w-20{
		width: 33%;
	}

	.webs-oficiales .width {
		width: 33%;
		margin-top: 1rem;
		margin-bottom: auto !important;
	}

	.mb-paso-2-inputs{
		margin-bottom: 0 !important;
	}

	.compra-paso-2 .card-paso-2{
		padding: 18px 0px !important;
	}

	.compra-paso-2 .div-precio{
		margin-top: 10px;
		padding-left: calc(var(--bs-gutter-x) * 1);
		padding-right: calc(var(--bs-gutter-x) * 1);
	}

	.compra-paso-2 .unidades{
		text-align: left !important;
	}

	.home-editoriales .card-small {
	  height: 80px;
	}

	/*PEDIDO*/
	#listado-productos-paso-2 {
		padding-left: 20px;
		padding-right: 20px;
	}

	#listado-productos-paso-2 .title {
		font-size: 10px;
	}

	#listado-productos-paso-2 .text-total {
		font-size: 13px;
	}

	#listado-productos-paso-2 .total-precio {
		font-size: 17px;
	}

	.resumen-compra .buscados-card-titulo {
		font-size: 15px;
		padding-right: 0;
	}

	.resumen-compra .listado-obras .card-listado{
		padding: 18px 12px !important;
	}

	.resumen-compra .listado-productos .card .unidades {
		text-align: start !important;
	}


}

#toast-container > div {
	opacity: 0.95;
}

.loader {
    width: 25px;
    height: 25px;
    border: 3px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

	&.opensearch-loader {
		position: absolute;
		top: 18px;
		right: 20px;
		border-color: rgb(255, 193, 7);
		border-bottom-color: transparent;
	}

}

//Opensearch buscador

#buscador-general {
	.card {
		padding: 0 !important;
		display: flex;
		flex-direction: row;
		gap: 1rem;
		align-items: center;

		img{
			height: fit-content;
			max-width: 100px;
		}

		.libro-editorial {
			font-size: 14px;
		}
	}

	.opensearch-results-container{
		.libro  {
			h6, p {
				color: #6d6f73;
				em {
					background: $color-primary;
				}
			}
			.data-opensearch-autores {
				font-size: 12px;
			}
		}
	}
}


@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

#buscador-general {
	@media (max-width: 1100px) {
		padding: 60px 20px 60px 20px;
	}
	@media (max-width: 576px) {
		padding: 60px 10px 60px 10px;
	}
}

@media (max-width: 992px) {
	.opensearch-results-container {
		.opensearch-results-row-0{
			@media (max-width: 530px) {
				.libro {
					padding-right: 0 !important;
					padding-left: 0 !important;
				}
				.card img{
					max-width: 60px !important;
				}
			}
		}
	}
}

#app > div, #app .home > div  {
	transition: opacity 0.5s ease;
}
